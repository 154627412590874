import CardWorker from '~~/workers/card.worker?worker'
import DBWorker from '~~/workers/db.worker?worker'

export default () => {
  const { refreshToken } = useFirebase()

  const cardWorker = ref(null)
  const dbWorker = ref(null)

  onBeforeMount(() => {
    cardWorker.value = new CardWorker()
    dbWorker.value = new DBWorker()
  })

  onMounted(async () => {
    await refreshToken()
  })

  onBeforeUnmount(() => {
    cardWorker.value.onmessage = null
    cardWorker.value.terminate()
    dbWorker.value.onmessage = null
    dbWorker.value.terminate()
  })

  return {
    cardWorker,
    dbWorker,
  }
}
